import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";

export default function BusinessType({
  filterCategory,
  setFilterCategory,
  setFilterDateTo,
  setFilterDateFrom,
}) {
  const handleChange = (evt) => {
    const milisecondsInDay = 86400000;
    const dayInMonth = 30;
    const currentDate = new Date();
    //setFilterDateFrom(new Date(+currentDate - dayInMonth * milisecondsInDay));
    // if(evt.target.textContent === "MatchAwards"){
		// 	setFilterDateFrom(new Date(+currentDate - dayInMonth * milisecondsInDay));
		// } else if(evt.target.textContent === "All"){
		// 	setFilterDateFrom(new Date(+currentDate - dayInMonth * milisecondsInDay));
		// }
    setFilterCategory(evt.target.textContent);
  };
  return (
    <List style={{ padding: "30px 0" }}>
      {/* {["All", "MatchAwards"].map((text, index) => ( */}
      {["MatchAwards"].map((text, index) => (
        <ListItem
          onClick={(e) => handleChange(e)}
          button
          style={
            text == filterCategory
              ? { color: "rgb(16, 156, 241)", fontWeight: 700 }
              : { color: "#344567" }
          }
          key={text}
        >
          <ListItemIcon style={{ minWidth: "auto", marginRight: "10px" }}>
            <PersonOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  );
}
