import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Table from "./Table";
import Filter from "./Filter";
import DrawerNfo from "../DrawerNfo";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { swaggerApi } from "../../api/swagger";
import { xlsApi } from "../../api/xls.config";
import { Button, Typography } from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import "./style.css";


//import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  contentShiftLeft: {
    width: `calc(100% - 300px)`,
    marginLeft: "auto",
    marginRight: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
function choseCategory(category = "") {
  const formatedCategory = category.toUpperCase();
  if (formatedCategory === "ALL") {
    return ["/awards-search", "awards_daterange"];
  } else {
    return ["/mf-awards-search", "awards_daterange"];
  }
}
// choosing category for get request to middleware for export table data by daterange to xls (for all and MF awards)
function choseCategoryXls(category = "") {
  const formatedCategory = category.toUpperCase();
  if (formatedCategory === "ALL") {
    return ["/awards-export-xls", "awards_daterange", "ALL"];
  } else {
    return ["/awards-export-xls", "awards_daterange", "MF"];
  }
}
function MainContent({
  open,
  openNfo,
  handleDrawerOpenNfo,
  handleDrawerCloseNfo,
  drawerData,
  totalContracts,
  setTotalContracts,
  searchQuery,
  selectedColums,
  setSelectedColums,
  filterDateTo,
  setFilterDateTo,
  filterDateFrom,
  setFilterDateFrom,
  mainData,
  setMainData,
  loading,
  setLoading,
  filterBySolNumb,
  setFilterBySolNumb,
  filterCategory,
  setFilterCategory,
}) {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const [exportData, setExportData] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    setError("");
    setMainData([]);
    if (filterCategory === "All") {
      setSelectedColums([
        { name: "Contract Title", status: true },
        { name: "City", status: true },
        { name: "Contract", status: true },
        { name: "Solicitation", status: true },
        { name: "Source", status: true },
        { name: "Amount", status: true },
      ]);
    } else {
      setSelectedColums([
        { name: "Contract Title", status: true },
        { name: "City", status: true },
        { name: "Contract", status: true },
        { name: "Solicitation", status: true },
        { name: "Source", status: true },
        { name: "Amount", status: true },
        { name: "Duns", status: true },
        { name: "UEI", status: true },
        { name: "Tax ID", status: true },
        { name: "Contact info", status: true },
      ]);
    }
    const selectedCategory = choseCategory(filterCategory);
    const formatedDateFrom = `${filterDateFrom.getFullYear()}-${
      filterDateFrom.getMonth().toString().length !== 1
        ? filterDateFrom.getMonth() + 1
        : filterDateFrom.getMonth() + 1
    }-${
      filterDateFrom.getDate().toString().length !== 1
        ? filterDateFrom.getDate()
        : "0" + filterDateFrom.getDate()
    }`;
    const formatedDateTo = `${filterDateTo.getFullYear()}-${
      filterDateTo.getMonth().toString().length !== 1
        ? filterDateTo.getMonth() + 1
        : filterDateTo.getMonth() + 1
    }-${
      filterDateTo.getDate().toString().length !== 1
        ? filterDateTo.getDate()
        : "0" + filterDateTo.getDate()
    }`;
    swaggerApi
      .post(selectedCategory[0], {
        [selectedCategory[1]]: {
          end_date: formatedDateTo,
          start_date: formatedDateFrom,
        },
      })
      .then(function (response) {
        setMainData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          setError(error.response.data);
        } else {
          setError("Network error");
        }
      });

  }, [filterDateFrom, filterDateTo, filterCategory]);
  // get request to middleware for export table data by daterange to xls (for all and MF awards)
  const selectedCategoryXls = choseCategoryXls(filterCategory);
  function handleSubmit(event) {
    var start = document.getElementById('date-picker-inline1').value; 
    start = start.replace(/[^a-zA-Z0-9]/g,'-');
    var end = document.getElementById('date-picker-inline2').value 
    end = end.replace(/[^a-zA-Z0-9]/g,'-'); 
    xlsApi
    .get(selectedCategoryXls[0], {
      params :  {
        end_date: end,
        start_date: start,
      whichAwards: selectedCategoryXls[2]},
      responseType:"arraybuffer",
      headers: {
        'content-type': 'application/vnd.ms-excel;charset=UTF-8',
      },
    })
    .then(function (response) {
      const blob = new Blob(
        [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
          const aEle = document.createElement('a');     // Create a label
          const href = window.URL.createObjectURL(blob);       // Create downloaded link
          aEle.href = href;
          aEle.download = "AwardsDashboard.xlsx";  // File name after download
          document.body.appendChild(aEle);
          aEle.click();     // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href) // Release blob object
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        setError(error.response.data);
      } else {
        setError("Network error");
      }
    });
  }
  const handleChange = (files) => {
    console.log(files);
  };
  return (
    <Container
      style={{ maxWidth: "100%", padding: "0 38px", marginBottom: "50px" }}
      className={clsx({
        [classes.contentShiftLeft]: open,
      })}
    >
      <Filter
        setTotalContracts={setTotalContracts}
        setSelectedColums={setSelectedColums}
        selectedColums={selectedColums}
        filterCategory={filterCategory}
        setFilterCategory={setFilterCategory}
        filterDateTo={filterDateTo}
        setFilterDateTo={setFilterDateTo}
        filterDateFrom={filterDateFrom}
        setFilterDateFrom={setFilterDateFrom}
        mainDataLength={mainData.length}
      />
      {loading ? (
        <div
          style={{
            height: "620px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={150} thickness={3} />
        </div>
      ) : mainData.length ? (
        <Table
          handleDrawerOpenNfo={handleDrawerOpenNfo}
          totalContracts={totalContracts}
          setTotalContracts={setTotalContracts}
          searchQuery={searchQuery}
          selectedColums={selectedColums}
          mainData={mainData}
          setExportData={setExportData}
          setFilterBySolNumb={setFilterBySolNumb}
        />
      ) : !error ? (
        <>
          <Typography variant="h2">No data found</Typography>
          <Typography style={{ fontSize: "22px" }} variant="body1">
            Please select another date
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h2">{error}</Typography>
          {/* <Typography style={{fontSize:'22px'}} variant="body1">Please select another date</Typography> */}
        </>
      )}
      <DrawerNfo
        isOpen={openNfo}
        handleDrawerCloseNfo={handleDrawerCloseNfo}
        drawerData={drawerData}
        filterBySolNumb={filterBySolNumb}
      />
      {mainData.length ? (
        <>
          <DropzoneDialog
            acceptedFiles={[".csv"]}
            cancelButtonText={"cancel"}
            submitButtonText={"submit"}
            open={openModal}
            onClose={() => setOpenModal(false)}
            showPreviews={true}
            showFileNamesInPreview={true}
            onSave={(files) => {
							// console.log("Files:", files);
							let formData = new FormData();
							files.map(el =>{
								console.log(el);
								formData.append('files', el, el.name);
							})
							swaggerApi
							.post('/mf-awards-import/api/file/multiple/upload', formData,{
								headers: {
									'Content-Type': 'multipart/form-data'
								}
						})
							.then(function (response) {
								console.log('send')
								console.log(files)
							})
							.catch(function (error) {
								console.log(error)
							});
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "40px",
            }}
          >
					{/* <Button
            style={{ color: "#fff", marginRight:"30px" }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            upload CSV
          </Button> */}
          
            {/* <CSVLink
              data={exportData}
              
              filename={"Match force awards.csv"}
              style={{ textDecoration: "none" }}
            >
              <Button
                style={{ color: "#fff" }}
                size="large"
                variant="contained"
                color="primary"
              >
                Download CSV
              </Button>
            </CSVLink> */}

            {/* <form
              method="post"
              onSubmit={event => this.handleSubmit(event)}> */}
              {/* <Button 
                //type="submit"
                style={{ color: "#fff" }}
                size="large"
                variant="contained"
                color="primary"
                onClick={event => handleSubmit(event)}
              >
                Download XLSX
              </Button> */}
            {/* </form> */}
          </div>
        </>
      ) : null}
    </Container>
  );
}

export default MainContent;
