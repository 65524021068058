import axios from "axios";

export const swaggerApi = axios.create({
  //baseURL:"http://216.117.177.3:5000",
  //baseURL:"http://208.234.30.132:5000",
  //baseURL:"http://216.117.155.35",
  baseURL:"https://awards.matchawards.com/",
  responseType: "json"
});

